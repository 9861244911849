const container = `
    width: 100%; 
    height: 100vh; 
    display: flex; 
    justify-content: center; 
    align-items: center;
    position: absolute;
    top: 0;
`

const containerContent = `
    display: flex; 
    flex-flow: column; 
    align-items: center; 
    text-align: center;
`

const h2 = `
    font-size: 60px;
    color: #cacedb;
    font-weight: 700;
    line-height: 36px;
    margin: 0 0 25px 0;
`

const h3 = `
    font-size: 35px;
    color: #3d5170;
    font-weight: 500;
    margin: 0 0 10px 0;
    line-height: 36px;
`

const p = `
    font-size: 15px;
    color: #818ea3;
    line-height: 23px;
`

export const ErrorComponent = `
    <div style="${container}">
        <div style="${containerContent}">
            <h2 style="${h2}">Sorry</h2>
            <h3 style="${h3}">The application is being updated.</h3>
            <p style="${p}">Please wait a few minutes...</p>
        </div>
    </div>
`