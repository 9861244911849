import { registerApplication, start, addErrorHandler, getAppStatus, LOAD_ERROR } from "single-spa"
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout"
import microfrontendLayout from "./microfrontend-layout.html"
import { ErrorComponent } from "./components"

const data = {
  errors: { ErrorComponent },
  loaders: {},
  props: {}
}

addErrorHandler(err => {
  if (getAppStatus(err.appOrParcelName) === LOAD_ERROR) {
    System.delete(System.resolve(err.appOrParcelName))
  }
})

const routes = constructRoutes(microfrontendLayout, data)
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name)
  }
})
const layoutEngine = constructLayoutEngine({ routes, applications })

applications.forEach(registerApplication)
layoutEngine.activate()
start()
